//
// Angular & Angular Material modifiers
//


// TOOLBAR
md-toolbar.md-tall.md-tall-flexible {
  @media (max-height: 1079px) {
    height: 80px;
    min-height: 80px;
    max-height: 80px;
  }
}

.md-toolbar-tools-auto-height {
  height: auto;
  max-height: initial;
}

// BUTTON
.md-button.md-icon-button-36 {
  line-height: 36px;
  width: 52px;
  height: 52px;
}

.md-button.md-icon-text-button {
  color: #757575;
  text-transform: none;

  span {
    display: inline-block;
    vertical-align: middle;
  }
}

.md-button.md-button-narrow {
  min-width: 36px;
}

.md-button.md-button-low {
  min-height: 24px;
  line-height: 24px;
}

// ICONS
.md-icon-36 {
  font-size: 36px;
  height: 36px;
  width: 36px;
}

.md-icon-48 {
  font-size: 48px;
  height: 48px;
  width: 48px;

  &.md-avatar-icon svg {
    height: 48px;
    width: 48px;
  }
}

// SIDENAV
.md-sidenav-wide {
  width: 400px;
}

.md-sidenav-narrow {
  width: 300px;
}

// LIST
md-list-item.md-list-item-alternative {
  padding-right: 0;

  .md-list-item-inner {
    padding-right: 0;
  }

  .md-avatar {
    border-radius: 0;
  }

  .md-list-item-text {
    border-bottom: $border-formElement;

    md-checkbox {
      margin-bottom: 0;
    }
  }
}

md-list-item.md-list-item-no-padding {
  padding: 0;
}


.md-select-dark {
  background-color: #d5d5d5;
  border-radius: 3px;
  padding: .25em .5em;

  md-select-value span {
    color: #2d2d2d;
  }
}

.md-datepicker-date {
  background-color: #d5d5d5;
  border-radius: 3px;
  padding: .25em .5em;
}
