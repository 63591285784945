.o-no-content {
  color: $cl-f-subheading;

  &--padding {
    padding: 1.5em;
  }

  &--padding-x {
    padding: 0 1.5em;
  }

  &--padding-y {
    padding: 1.5em 0;
  }
}
