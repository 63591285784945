.c-incident-log {
  &__status {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    line-height: 1.5rem;
    padding-left: 1.5rem;
    margin-bottom: 1rem;
    margin-left: -1.5rem;
    height: 24px;
    width: 128px;
    background: $cl-f-subheading; // wasn't sure if this was an appropriate var to use, but it is the correct colour hex
    color: #FFF;

    &.s-is-accepted {
      background-color: $cl-gnaas-primary;
    }

    &.s-is-refused {
      background-color: $cl-gnaas-warning;
    }
  }
}
