.c-crew-list {
  background-color: $cl-bg-card;

  &__item {
    @include hasSelectedStateBorder;
    outline: 0;
    display: block;

    &.s-is-selected {
      background-color: $cl-bg-base;
    }
  }
}
