//
// Angular & Angular Material class overrides
//
[ng-cloak] {
    display: none;
}

md-select-menu md-content {
  background-color: $cl-bg-card;
}

md-dialog md-dialog-actions {
  padding: { left: 16px; right: 16px; }
  border-top: $border-formElement;
  margin-top: -1px;
}

button.md-button.md-raised:not(.md-primary):not(.md-warn):not(.md-accent):not([disabled]) {
  border: 1px solid $cl-gnaas-primary;
}

.md-toolbar-tools {
  min-height: 64px;

  > .md-button:first-child {
    margin-left: 0 !important;
  }

  > .md-button:last-child {
    margin-right: 0 !important;
  }
}

md-radio-group {
  display: block;
  margin-top: .5em;
}

// TABS
md-tabs md-ink-bar {
  color: $cl-gnaas-primary;
  background-color: $cl-gnaas-primary;
}
