.c-crew-member-profile {
  &__credentials {
    padding: 3em 2em;

    &__name, &__title {
      margin: 0;
    }

    &__title {
      color: $cl-f-subheading;
    }
  }

  &__avatar {
    border: 2px solid $cl-gnaas-accent;
    border-radius: 50%;
    display: block;
    width: 124px;
    height: 124px;
    object-fit: cover;
  }

  &__icon {
    border-radius: 30px;
    width: 24px;
    height: 24px;
    margin: 12px 16px;
  }

  md-list md-list-item > &__icon {
    background-color: $cl-gnaas-primary;
    padding: 14px;
  }
}
