.drop-down {
  .md-button {
    &:after {
      content: '';
      width: 0;
      height: 0;
      margin-left: 10px;
      margin-right: 10px;
      border-style: solid;
      border-width: 8px 6px 0 6px;
      border-color: $cl-f-base transparent transparent transparent;
    }

    div {
      margin-left: 10px;
    }
  }
}
