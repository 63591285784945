$calendarAgendaComponent: 'c-calendar-agenda';

.#{$calendarAgendaComponent} {
    margin-bottom: 1em;
    position: relative;

    &__gradient {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2em;
        background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
    }

    &__textarea {
        max-height: 400px;
        overflow-y: scroll;
        padding: 0 1.5em 1.5em;
    }
}
