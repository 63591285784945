$formDataComponent: 'c-form-data';

.#{$formDataComponent} {
  &__cell {
    padding: .5em 1.5em;
    overflow: hidden;

    span {
      display: block;
      margin: .25em 0;
    }

    &--with-borders {
      border-right: $border-formElement;
      border-bottom: $border-formElement;
    }
  }

  &__row {
    border-bottom: $border-formElement;

    &:last-child, &:last-of-type {
      border-bottom: none;
    }

    .#{$formDataComponent}__cell {
      border-right: $border-formElement;

      &:last-child, &:last-of-type {
        border-right: none;
      }
    }
  }

  &__column {
    .#{$formDataComponent}__cell {
      border-bottom: $border-formElement;

      &:last-child, &:last-of-type {
        border-bottom: none;
      }
    }
  }

  &__button {
    margin-right: 1em;
  }
}
