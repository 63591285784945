.c-audit-tool {
  &__tabs { z-index: 3; }

  &__tab {
    border-bottom: 0 solid $cl-gnaas-primary;
    color: $cl-f-subheading;
    cursor: pointer;
    display: inline-block;
    outline: 0;
    overflow: hidden;
    padding: 1.25em 1.25em 1.25em;
    position: relative;
    text-transform: uppercase;
    vertical-align: top;

    &:after {
      content: '';
      background-color: $cl-gnaas-primary;
      height: 4px;
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translateY(1.25em);
      transition: transform .2s linear;
      width: 100%;
    }

    &.s-is-active {
      color: $cl-gnaas-primary;

      &:after {
        transform: translateY(1em);
        bottom: 1em;
      }
    }
  }

  &__tab-content { padding: 0.5em 1.5em; }

  &__search-criteria {
    &__sidenav {
      z-index: 3;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;

      &-list { margin: 0; }

      &-list-item { display: block; }

      &-link {
        position: relative;
        text-align: left;
        text-transform: capitalize;
        font-size: 1.125em;
        color: #FFF;
        width: 100%;
        margin: 0;
        padding: .5rem 1.25rem;
        border: { right: 6px solid transparent; };

        &.is-active {
          background: #212121;
          color: $cl-gnaas-accent;
          border-right: 6px solid $cl-gnaas-accent;
          border-radius: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      md-icon {
        color: $cl-gnaas-accent;
      }
    }

    &__fields { margin-bottom: 2em; }

    &__query-row { margin: .75em 0; }

    &__query-row-buttons {
      margin: .75em .5em 0 .75em;
      text-align: right;
    }

    &__plus-icon,
    &__minus-icon {
      cursor: pointer;
      position: relative;
      width: 32px;
      top: 2px;
      margin: 0 0 0 .125em;
    }
  }

  &__toolbar {
    &__save {
      cursor: pointer;
      display: flex;
      line-height: 32px;
      font-size: 0.8em;

      &:before {
        content: '';
        display: inline-block;
        background-image: url('../assets/images/icons/add-box-36px.svg');
        background-size: 32px;
        background-repeat: no-repeat;
        width: 32px;
        height: 32px;
        padding: { right: 0.25em; };
        box-sizing: content-box;
      }

      &.is-disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
  }

  &__results { margin: { top: 2.5em; }; }

  &__data-grid {
    height: 800px;
    border: none;
    border-radius: 5px;

    .ui-grid-contents-wrapper {
      padding-bottom: 2.125em;
    }

    .ui-grid-filter-container {
      padding: 0;
    }

    .ui-grid-row {
      border-bottom: 1px solid #D5D5D5;
    }

    .ui-grid-filter-button [class^="ui-grid-icon"] {
      line-height: initial;
      margin-top: 6px;
      right: 5px;
      color: $cl-gnaas-primary;
    }

    .ui-grid-header-cell {
      padding: 10px 15px;
      background-color: $cl-gnaas-primary;
      color: #FFF;
      font-weight: 500;
      font-size: 1.125em;
      border-color: #D5D5D5;
      box-sizing: border-box;

      &:first-of-type { border-top-left-radius: 5px; }

      &:last-of-type { border-top-right-radius: 5px; }

      .ui-grid-cell-contents {
        padding: 0 0 10px 0;
        float: left;
      }
    }

    .ui-grid-cell-contents { padding: 7.5px 15px; }

    .ui-grid-column-menu-button {
      top: 10px;
      right: 10px;
      z-index: 1000;

      .ui-grid-icon-angle-down {
        vertical-align: middle;

        &:before { margin: 0; }
      }

      &-last-col { margin: 0; }
    }

    input[type="text"].ui-grid-filter-input {
      box-sizing: border-box;
      border: 0px;
      border-radius: 5px;
      padding: 5px;
    }

    .ui-grid-pager-panel { padding: 5px; }

    .ui-grid-pager-control {
      min-width: 0;

      input {
        padding: 0 10px;
        box-sizing: border-box;
      }

      button { height: 26px; }
    }

    .ui-grid-pager-count-container { margin-top: 0; }
  }

  &__query-error {
    margin-top: 2.5em;
    padding: 0 10px;
    color: red;
  }
}
