// **************
//     COLORS
// **************

// Backgrounds
$cl-bg-base: #F3F3F3;
$cl-bg-card: #FFF;
$cl-bg-borders: #D5D5D5;

// Fonts (typography)
$cl-f-base: rgba(0,0,0,0.87);
$cl-f-disabled: rgba(0,0,0,0.26);
$cl-f-subheading: #8E8E8E;

// GNAAS theme colors
$cl-gnaas-primary: #4C617C;
$cl-gnaas-accent: #FFC742;
$cl-gnaas-warning: #E94446;


// *******************
//     TRANSITIONS
// *******************

$transition-activeStateBorder: border-right-width .2s linear;

// ***************
//     BORDERS
// ***************
$border-formElement: 1px solid $cl-bg-borders;
