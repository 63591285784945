$border: 1px solid $cl-bg-borders;

.c-compartment {
  border: $border;
  border-radius: 5px;
  overflow: hidden;

  &__header {
    background-color: $cl-bg-base;
  }

  &__title {
    font-size: 1.25em;
    padding: .75rem 1rem;
  }

  &__toggle-button {
    display: block;
    line-height: 1;
    transition: transform .3s ease-in-out;

    &.s-toggled {
      transform: rotate(180deg);
    }
  }

  &__content {
    height: auto;
    overflow: hidden;
    transition: height .25s cubic-bezier(0.35, 0, 0.25, 1);

    & > *:last-child > .c-compartment {
      border-bottom: none;
      border-bottom-left-radius: 0;
    }
  }

  &__columns {
    padding: 1rem;
    color: $cl-f-subheading;
    font-size: .875em;
  }

  &__item {
    padding: 0 1rem;
  }

  &__item-attribute {
    padding: .75rem 0;
  }

  &__item-divider {
    margin-left: 1rem;
  }

  &--nested {
    border-right: none;
    border-radius: 5px 0 0 5px;
    margin-left: 1rem;

    .c-compartment__item-attribute:nth-child(2) {
      margin-left: -10px;
    }
    .c-compartment__item-attribute:nth-child(3) {
      margin-left: 6px;
    }

    .c-compartment--nested {
      .c-compartment__item-attribute:nth-child(2) {
        margin-left: -20px;
      }
      .c-compartment__item-attribute:nth-child(3) {
        margin-left: 12px;
      }
    }
  }
}
