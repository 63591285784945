body {
  color: $cl-f-base;
  font-family: 'Cabin', sans-serif;
  font-weight: 400;
  min-width: 960px;
  overflow-y: hidden; // prevent scroll drag
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

ul {
  padding: 0;
}

table, th, td {
  font: inherit;
}

.app-container {
  height: 100%;
  overflow: hidden;
}

.app {
  position: relative;
}
