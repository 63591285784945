.c-search-input {
  margin: 0 0.25em;

  input {
    box-sizing: content-box;
    background: #F3F3F3;
    border-radius: 5px;
    border: 1px solid #D5D5D5 !important;
    padding: .125em .5em !important;
    //height: 100%;
  }

  label {
    padding: 0 .5em !important;
  }

  &.md-input-focused label,
  &.md-input-has-value label{
    display: none;
  }

  .md-errors-spacer {
    display: none;
  }
}
