$component: 'c-observations';

.#{$component} {
  font-size: 14px;

  &__cell {
    border-right: $border-formElement;
    border-bottom: $border-formElement;
    padding: 1rem .25rem;
    overflow: hidden;

    &:last-of-type {
      border-right: 0;
    }
  }

  &__cell--title {
    color: $cl-f-subheading;
    text-align: left;
  }

  &__cell--title-group {
    color: $cl-f-subheading;
    padding: .4375rem .25rem;
    text-align: center;
  }

  &__cell--disabled {
    background-color: $cl-bg-base;
  }

  &__cell--centered {
    text-align: center;
  }

  &__input {
    border: none;
    outline: none;
    width: 100%;

    &--date {
      padding: 0;

      md-datepicker {
        margin: 0;
        padding-top: 3px;
      }

      input.md-datepicker-input {
        font-size: 14px;
        min-width: 0;
        padding: 0;
      }

      .md-datepicker-input-container {
        margin: { left: 6px !important; };
      }
    }
  }
}
