.c-search-select {
  box-sizing: border-box;
  margin: 0 .25em;
  width: 100%;

  md-select {
    background: #F3F3F3;
    border-radius: 5px;
    border: 1px solid #D5D5D5;
    padding: .125em .5em;
  }

  .md-select-value {
    background-image: none !important;
    border-bottom: none !important;
  }

  &:first-of-type {
    margin-left: 0;
  }
}
