.c-login {
  background-color: $cl-gnaas-primary;
  position: absolute;
  min-height: 100%;
  width: 100%;

  &__branding {
    color: #FFF;
    font-weight: 500;
    margin: 1rem;
    text-align: center;
  }

  &__logo {
    height: 80px;
    margin-bottom: 2rem;
  }

  &__title {
    font-size: 2.25em;
    font-weight: 400;
    margin: .5rem;
  }

  &__org {
    font-size: 1.75em;
    margin: .5rem;
  }

  &__form {
    @extend .o-card;
    width: 576px;

    &__title {
      color: $cl-f-subheading;
      padding: 1em 1.5em;
    }

    &__button-bar {
      color: $cl-f-subheading;
      padding: 1em .75em;
    }
  }
}
