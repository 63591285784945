$sidenavComponent: 'c-sidenav-menu';

.#{$sidenavComponent} {
  padding: 1em 0;

  &__heading {
    color: $cl-f-subheading;
    padding: 0 1em;
    margin: 1em 0 0;
  }

  &__list {
    padding: 1em 0;
    margin: 0;
  }

  &__link {
    @include hasSelectedStateBorder;
    border-radius: 0;
    display: block;
    font-size: 1.25em;
    line-height: 36px;
    margin: 0;
    overflow: hidden;
    padding: .5rem 1rem;
    text-align: left;
    text-decoration: none;
    text-transform: none;
    white-space: normal;
    width: 100%;

    &__toggle-icon {
      display: block;
      line-height: 32px;
      margin-left: auto;
      speak: none;
      vertical-align: middle;
      transition: transform .3s ease-in-out;
    }

    &.s-is-selected {
      background-color: #212121;
      color: $cl-gnaas-accent;
    }

    &.s-is-toggled {
      .#{$sidenavComponent}__link__toggle-icon {
        transform: rotate(180deg);
      }
    }

    &.s-child-active {
      color: $cl-gnaas-accent;

      .#{$sidenavComponent}__link__toggle-icon md-icon {
        color: $cl-gnaas-accent;
      }
    }
  }

  &__submenu {
    overflow: hidden;
    transition: height .25s cubic-bezier(0.35, 0, 0.25, 1);

    .#{$sidenavComponent}__link {
      padding-left: 2.5rem;
    }
  }
}
