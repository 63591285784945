.form-date {
  @extend %abstract-form-element;

  label {
    color: $cl-f-subheading;
    display: block;
    transform: translate3d(0,6px,0) scale(.75);
    z-index: 1;
    transform-origin: left top;
  }

  md-datepicker {
    color: $cl-f-subheading !important;
    display: block;
    margin-top: 7px;
    overflow: visible;
    padding-right: 0;
    width: 100%;
  }

  .md-datepicker-input-container {
    border: none;
    margin-left: 0;
    padding-left: 0 !important;
    width: auto;
  }

  input.md-datepicker-input {
    font-size: 16px;

    &::placeholder {
      color: $cl-f-subheading !important;
    }

    &[disabled] {
      color: $cl-f-disabled;
    }
  }

  .md-datepicker-button {
    display: none;
  }

  .validation-messages {
    font-size: 12px;
    color: #dd2c00;
  }

  .md-datepicker-expand-triangle {
    margin-top: 2px;
  }

  &--narrow .md-datepicker-expand-triangle {
    margin-top: 7px;
  }

  &--embedded {
    border: none !important;
    padding: 0;

    md-datepicker {
      margin-top: 0;
      padding: {
        top: 10px;
        right: 10px;
        bottom: 3px;
      }
      width: auto;
    }

    .md-datepicker-expand-triangle {
      margin-top: 2px;
    }
  }
}
