.c-request-list-item {
  @extend .o-card;
  @extend .o-card--wide;

  background-color: $cl-bg-card;
  margin: .5em 0;
  width: 100%;
  min-height: 144px;

  &__section {
    min-height: 144px;
    padding: 1em 1.5em;

    &--actions {
      padding: 1.5em 0;
    }
  }

  &__button {
    max-width: 192px;
    width: 80%;
    width: calc(100% - 2em);
  }
}
