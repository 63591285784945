$mapSidenavWidth: 300px;

.c-map-controls {
  color: $cl-f-subheading;
  position: relative;

  &__group {
    border-right: $border-formElement;
    padding: 0 .75em;

    &:last-child,
    &:last-of-type {
      border-right: 0;
    }
  }

  &__input {
    background-color: #F3F3F3;
    border: 1px solid #959595;
    box-shadow: rgba(0, 0, 0, 0.258824) 0px 2px 5px 0px;
    outline: none;
    padding: .5em 1em;

    &:first-child,
    &:first-of-type {
      border-right: none;
      border-top-left-radius: .25em;
      border-bottom-left-radius: .25em;
    }
  }

  &__search-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin: 0;
  }

  ::-webkit-input-placeholder { color: $cl-f-subheading; }
  :-moz-placeholder { color: $cl-f-subheading; }
  ::-moz-placeholder { color: $cl-f-subheading; }
  :-ms-input-placeholder { color: $cl-f-subheading; }

  &__sidebar-toggle {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin: 0;
    min-width: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate3d(0, -50%, 0);
    transition: transform .4s cubic-bezier(0.25, 0.8, 0.25, 1);

    md-icon {
      transform: rotate(180deg);
    }

    &.is-toggled {
      transform: translate3d(-#{$mapSidenavWidth}, -50%, 0);

      md-icon {
        transform: rotate(0);
      }
    }
  }
}
