.c-calendar-cell {
  border: $border-formElement;
  cursor: pointer;
  font-size: 13px;
  line-height: 1.5;
  margin: { left: -1px; top: -1px; }
  outline: none;
  overflow: hidden;
  padding: .375rem 0 .375rem;
  position: relative;

  &--disabled {
    opacity: 0.5;
  }

  &--today {
    background-color: $cl-bg-base;
  }

  &__date {
    padding: 0 .375rem .3125rem;
    font-weight: 500;
  }

  &__event,
  &__more {
    font-variant: normal;
    margin-bottom: 1px;
    padding: 0 .375rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__more {
    margin-bottom: 0;
    border-top: $border-formElement;
    font-weight: 500;
  }
}
