.map-popup {
  &__header {
    background-color: $cl-gnaas-primary;
    color: $cl-bg-base;
    font-size: 16px;
    padding:  .75em 1.75em .75em .75em
  }

  &__content {
    padding: 1em;

    ul {
      list-style: none;
      margin: 0;
    }
  }

  &__content-label {
    @extend .f-label;
    margin-bottom: .5em;
  }
}
