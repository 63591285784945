.c-avatar-item {
  cursor: pointer;

  &__avatar {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    margin: 12px 16px;
    object-fit: cover;
  }

  md-list-item &__avatar--svg.md-avatar-icon {
    margin: 8px;
    background-color: transparent;
  }

  &.s-active {
    background-color: $cl-bg-base;
    color: $cl-gnaas-accent;
  }
}
