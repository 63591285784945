.ui-grid {
  &, .ui-grid-viewport {
    height: auto !important;
    padding-bottom: 1px;
  }

  .ui-grid-header-cell {
    background-color: $cl-gnaas-primary;
    color: #FFF;
    font-weight: 500;
    font-size: 1.125em;
    border-color: #D5D5D5;

    .ui-grid-header-cell-label {
      margin-left: 5px;
    }
  }

  .ui-grid-icon-cancel {
    color: #212121;
  }

  .ui-grid-pager-control {
    min-width: 0;

    input {
      padding: 0 10px;
      box-sizing: border-box;
    }

    button {
      height: 26px;
    }
  }

  .ui-grid-pager-count-container {
    margin-top: 0;
  }

  &.with-pagination .ui-grid-contents-wrapper {
    padding-bottom: 2.125em;
  }

  .ui-grid-pager-panel {
    padding: .25em;
  }

  .ui-grid-cell.ui-grid-cell-bg-red {
    background-color: rgba($cl-gnaas-warning, .2);
  }

  .ui-grid-row:nth-child(even) .ui-grid-cell.ui-grid-cell-bg-red {
    background-color: rgba($cl-gnaas-warning, .3);
  }
}
