.attachment {
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;
  display: block;
  margin: .5rem;
  overflow: hidden;
  padding: .5rem;
  word-break: break-word;
  width: 200px;
}
