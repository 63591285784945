.o-card {
  background-color: $cl-bg-card;
  border-radius: 4px;
  margin: auto;
  max-width: 100%;
  overflow: hidden;
  width: 704px;

  &--wide {
    width: 100%;
  }
}
