$controlsObject: 'c-calendar-controls';

.#{$controlsObject} {
  &__button {
    &:first-child {
      margin-left: 0;
    }

    &__icon {
      display: block;
      speak: none;
    }

    &--next, &--previous {
      min-width: auto;
    }

    &--next {
      margin-left: 0;
    }

    &--previous {
      margin-right: 0;
    }
  }

  &__date {
    display: inline-block;
    margin-left: 8px;
    vertical-align: middle;
  }

  &__date-picker {
    position: relative;
    display: inline-block;
    padding: 0 26px;
    right: 16px;
    border: none;
    min-height: 36px;
    min-width: 88px;
    vertical-align: middle;

    input.md-datepicker-input {
      font-size: 18px;
    }

    .md-datepicker-expand-triangle {
      margin-top: 0;
    }
  }
}
