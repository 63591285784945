.animate-form-element-if.ng-enter, .animate-form-element-if.ng-leave {
  transition: all linear .25s;
}

.animate-form-element-if.ng-enter,
.animate-form-element-if.ng-leave.ng-leave-active {
  height: 0;
  opacity: 0;
}

.animate-form-element-if.ng-leave,
.animate-form-element-if.ng-enter.ng-enter-active {
  height: 65px;
  opacity: 1;
}
