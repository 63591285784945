.c-calendar {
  padding: 1em 1.5em;
  letter-spacing: 0.14px;

  .expiring-items-label {
    cursor: pointer;
  }

  &__title-row {
    color: $cl-f-subheading;
    min-height: 48px !important;  // !important required to override flexbox
  }

  &__title-cell {
    padding: 1em 0 1em 0.5em;
  }

  &__row {
    min-height: 76px !important;  // // !important required to override flexbox
  }
}
