.c-filter {
  box-sizing: border-box;
  padding: 1em;
  position: relative;
  width: 100%;

  input {
    background-color: $cl-bg-borders;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    max-width: 640px;
    outline: none;
    padding: 1em 1em 1em 3em;
    width: 100%;
  }

  &--small {
    max-width: 33%;
  }

  md-icon {
    color: $cl-f-subheading;
    position: absolute;
    left: 1.33333em;
    top: 1.2085em;
  }

  ::-webkit-input-placeholder { color: $cl-f-subheading; }
  :-moz-placeholder { color: $cl-f-subheading; }
  ::-moz-placeholder { color: $cl-f-subheading; }
  :-ms-input-placeholder { color: $cl-f-subheading; }
}
