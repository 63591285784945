.form-radio-group {
  @extend %abstract-form-element;

  md-radio-button {
    display: inline-block;
    margin-right: 1.5em;

    &:last-child,
    &:last-of-type {
      margin-right: 0;
    }
  }
}
