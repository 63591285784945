$sidenavComponent: 'c-sidenav';

.#{$sidenavComponent} {
  &__logo {
    width: auto;
    max-width: 80%;
    height: 100%;
    margin: auto;

    @media (max-width: 1279px) {
      margin: auto 0;
    }
  }
}
