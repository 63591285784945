$calendarEventComponent: 'c-calendar-events';

.#{$calendarEventComponent} {
    margin-bottom: 1em;

    &__item {
      cursor: pointer;
      padding-left: 1.5em;
      position: relative;
      outline: none;
    }

    &__text {
        border-bottom: $border-formElement;
        padding: 1em 1.5em 1em 0;
    }

    &__time {
        color: $cl-f-subheading;
        margin: 0 0 .25em;
    }

    &__title {
        font-weight: 400;
        margin: 0;
    }

    &__color {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: .375em;
    }
}
