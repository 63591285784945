.form-input {
  @extend %abstract-form-element;

  md-input-container input {
    border: none;
    padding-left: 0 !important;
  }

  &.s-is-disabled input,
  &.s-is-disabled label {
    background-image: none;
    color: $cl-f-disabled;
  }
}
