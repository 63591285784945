.photo {
  margin: .5rem;
  height: 150px;
  width: 200px;

  &__container {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%;
  }

  &__content {
    border-radius: 10px;
    display: block;
    max-width: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}
