$breadcrumbsComponent: 'c-breadcrumbs';

.#{$breadcrumbsComponent} {
  margin: 0 0 2px;
  font-size: .875em;

  &__chevron {
    margin: 0 4px;
  }

  @media (min-height: 1079px) {
    margin-bottom: 1.5em;
  }
}
