// TYPOGRAPHY
.f-cl-secondary {
  color: $cl-f-subheading;
}

.f-subheading {
  color: $cl-f-subheading;
  font: { size: 1.125rem; weight: 400; }

  &--small {
    font-size: .875rem;
  }

  &--light {
    color: $cl-bg-base;
  }

  &--no-margin {
    margin-bottom: 0;
  }
}

.f-label {
  color: $cl-f-subheading;
  font-size: 0.875rem;
}

// TODO Should these go in trumps?
.f-toolbar-title {
  font-size: 32px !important;

  @media (max-height: 1079px) {
    font-size: 28px !important;
  }
}

.f-sidenav-title {
  font-size: 24px !important;
}
