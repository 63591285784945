$paginationObject: 'c-pagination';

.#{$paginationObject} {
  border-radius: 2px;
  border: 1px solid $cl-bg-borders;

  &__button {
    border-radius: 0;
    border-right: 1px solid $cl-bg-borders;
    margin: 0;
    min-width: 36px;

    &:last-child, &:last-of-type {
      border-right: none;
    }

    .md-ripple-container {
      border-radius: 0;
    }

    &--is-active {
      background-color: rgba(158,158,158,0.2)
    }

    &--previous, &--next {
      background-color: $cl-bg-borders;
    }

    &--previous .md-ripple-container {
      border-top-left-radius: 1px;
      border-bottom-left-radius: 1px;
    }

    &--next .md-ripple-container {
      border-top-right-radius: 1px;
      border-bottom-right-radius: 1px;
    }
  }
}
