.c-dashboard {
  &__controls {
    padding: .5em 1.5em;
  }

  &__vehicle-callsign-status,
  &__incident {
    margin: .5em 0;
  }
}

.c-shift-checkbox {
  margin: 0;

  & > .md-ink-ripple {
    right: 25px;
    left: auto;
  }

  .md-label {
    display: inline-flex;
    margin: 0;
  }
}
