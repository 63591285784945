$topbarComponent: 'c-topbar';

.#{$topbarComponent} {
  &__avatar-item {
    &:after {
      content: '';
      width: 0;
      height: 0;
      margin: { top: 28px; right: 16px; bottom: 28px; left: 15px;  };
      border-style: solid;
      border-width: 8px 6px 0 6px;
      border-color: #ffffff transparent transparent transparent;
    }

    &__avatar {
      width: 40px;
      height: 40px;
    }

    span {
      margin: 12px 0px;
    }
  }

  &__menu-icon {
    display: none;
  }

  @media (max-width: 1279px) {
    &__menu-icon {
      display: block;
    }
  }
}
