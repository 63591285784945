.c-tab-group {
  z-index: 1;

  &__tab {
    border-bottom: 0 solid $cl-gnaas-primary;
    color: $cl-f-subheading;
    cursor: pointer;
    display: inline-block;
    outline: 0;
    overflow: hidden;
    padding: 1em 1em 1.25em;
    position: relative;
    text-transform: uppercase;
    vertical-align: top;

    &:after {
      content: '';
      background-color: $cl-gnaas-primary;
      height: 4px;
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translateY(1.25em);
      transition: transform .2s linear;
      width: 100%;
    }

    &.s-is-active {
      color: $cl-gnaas-primary;

      &:after {
        transform: translateY(1em);
        bottom: 1em;
      }
    }
  }
}

