.c-resource-item {
  padding: 1em 1.5em;

  &__block {
    margin-bottom: 1em;
  }

  &__label {
    color: $cl-f-subheading;
    font-size: 0.875em;
    line-height: 1.5em;
    margin-bottom: 0;
  }

  &__value {
    line-height: 1.5em;
    margin: 0;
  }

  &__button {
    margin: 1em 0 0;
  }
}
