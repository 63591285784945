$calendarDayComponent: 'c-calendar-day';

.#{$calendarDayComponent} {
  &__block {
    border-bottom: $border-formElement;
    padding: 1em 0;

    &:last-child,
    &:last-of-type {
      border-bottom: none;
    }
  }

  &__actions {
    text-align: right;
    padding: 0 1.5em;
  }
}
