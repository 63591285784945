.c-search-datepicker {
  margin: 0 0.25em;

  md-datepicker {
    display: block;
    background: #F3F3F3;
    border-radius: 5px;
    border: 1px solid #D5D5D5;
    padding: .125em .5em;
    margin: 0;
  }

  input.md-datepicker-input {
    font-size: 15px;
    height: auto;
    float: none;
  }

  .md-datepicker-input-container {
    margin-left: 0;
    width: 100%;
  }
  
  button.md-datepicker-triangle-button {
    top: -3px;
    right: 4px;
  }

  input { border-bottom: none; }
  .md-errors-spacer { display: none; }
}
