.information-bar {
  $typeMap: (
    'info': ($cl-gnaas-primary, #fff),
    'warn': ($cl-gnaas-accent, #000),
    'error': ($cl-gnaas-warning, #fff),
  );

  @each $type, $value in $typeMap {
    &.bg-#{$type} {
      background-color: nth($value, 1);
      .label { color: nth($value, 2); }
    }
  }
}
