// UTILS

// Colors
.u-bg-darker {
  background-color: $cl-bg-base;
}

.u-bg-base {
  background-color: $cl-bg-base;
}

.u-bg-primary {
  background-color: $cl-gnaas-primary;
}

// SVG Fills
.u-svg-fill-green {
  stroke-width: 5px;
  stroke: $cl-gnaas-primary;
  fill: $cl-gnaas-primary;
}

.u-svg-fill-amber {
  fill: $cl-gnaas-accent;
}

.u-svg-fill-red {
  fill: $cl-gnaas-warning;
}

// Sizing
.u-box-sizing-border-box {
  box-sizing: border-box;
}

// Alignment
.u-text-align-center {
  text-align: center;
}

// Positioning
.u-pos-relative {
  position: relative;
}

.u-pos-absolute {
  position: absolute;
}

// Display
.u-display-block {
  display: block;
}

.u-display-inline-block {
  display: inline-block;
}

// Borders
.u-border-left {
  border-left: 1px solid $cl-bg-borders;
}

.u-border-right {
  border-right: 1px solid $cl-bg-borders;
}

.u-border-top {
  border-top: 1px solid $cl-bg-borders;
}

.u-border-bottom {
  border-bottom: 1px solid $cl-bg-borders;
}

// Fill container height or width
.u-fill-height {
  width: auto;
  height: 100%;
  margin: auto;
  max-width: 100%;
}

.u-fill-width {
  width: 100%;
  height: auto;
  margin: auto;
  max-height: 100%;
}

// Misc
.u-no-outline {
  outline: 0;
}

// Padding & Margins
$sizeMap: (
    'narrow': .5rem,
    'normal': 1rem,
    'wide': 1.5rem
);

@each $size, $unit in $sizeMap {
  .u-padding-#{$size} { padding: $unit; }
  .u-padding-#{$size}-x { padding: { left: $unit; right: $unit; }}
  .u-padding-#{$size}-y { padding: { top: $unit; bottom: $unit; }}
  .u-padding-#{$size}-right { padding-right: $unit; }
  .u-padding-#{$size}-left { padding-left: $unit; }
  .u-padding-#{$size}-top { padding-top: $unit; }
  .u-padding-#{$size}-bottom { padding-bottom: $unit; }

  .u-margin-#{$size} { margin: $unit; }
  .u-margin-#{$size}-x { margin: { left: $unit; right: $unit; }}
  .u-margin-#{$size}-y { margin: { top: $unit; bottom: $unit; }}
  .u-margin-#{$size}-right { margin-right: $unit; }
  .u-margin-#{$size}-left { margin-left: $unit; }
  .u-margin-#{$size}-top { margin-top: $unit; }
  .u-margin-#{$size}-bottom { margin-bottom: $unit; }
}

.u-login-warning {
  background-color: $cl-gnaas-warning;
  padding: .75rem 0;
  text-align: center;
}
