.c-table-list {
  padding: 1em;

  &__table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;

    thead th {
      color: $cl-f-subheading;
      padding: 1em .25rem;
      text-align: left;
    }

    tbody tr {
      border-bottom: 1px solid $cl-bg-borders;

      &:last-child, &:last-of-type {
        border-bottom: none;
      }

      &.s-warn {
        background-color: #fbdde0;
      }
    }

    tbody td {
      font-size: 1.25em;
      overflow: hidden;
      padding: .25rem;
      vertical-align: middle;

      &.c-table-list__button {
        text-align: right;
      }
    }

    &.s-no-button tbody td {
      padding: { top: .5rem; bottom: .5rem; }
    }

    input {
      background-color: #d5d5d5;
      border: none;
      border-radius: 3px;
      padding: 0 5px;

      &[type="number"]::-webkit-outer-spin-button,
      &[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &[type="number"] {
        -moz-appearance: textfield;
      }

      &.ng-dirty.ng-invalid {
        border: 1px solid $cl-gnaas-warning;
      }
    }

    md-checkbox {
      margin-bottom: 0;
    }

    .md-button {
      height: 32px;
      min-height: 32px;
      line-height: 32px;
    }
  }
}

form.ng-submitted .c-table-list .c-table-list__table tbody td input.ng-invalid {
  border: 1px solid $cl-gnaas-warning;
}
