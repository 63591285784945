$checkboxList: 'form-checkbox-list';
.#{$checkboxList} {
  &__actions {
    border: {
      bottom: $border-formElement;
      top: $border-formElement;
    }
    margin-top: -1px;
    padding: .5em 1.5em;
  }

  &__label {
    color: $cl-f-subheading;
    line-height: 2.5;
    overflow: hidden;
  }

  &__toggle-button {
    display: block;
    line-height: 1;
    transition: transform .3s ease-in-out;

    &.s-toggled {
      transform: rotate(180deg);
    }
  }

  &__items {
    overflow: hidden;
    transition: height .25s cubic-bezier(0.35, 0, 0.25, 1);
  }
}
