.form-checkbox {
  @extend %abstract-form-element;

  padding: 1em 1.5em;

  &.s-checked {
    background-color: $cl-bg-base;
  }

  .md-label {
    color: $cl-f-subheading;
  }

  [layout="row"] > &:last-child {
    border-right: $border-formElement;
  }

  [layout="row"] > &.flex,
  [layout="row"] > &.flex-33:nth-child(3n+3),
  [layout="row"] > &.flex-50:nth-child(2n) {
      border-right: none;
  }

  md-checkbox {
    margin-bottom: 0;
    width: 100%;

    .md-container {
      left: auto;
      right: 0;
    }

    .md-label {
      margin-left: 0;
      padding-right: 30px;
    }
  }
}
