.form-label {
  border-bottom: $border-formElement;
  border-top: $border-formElement;
  color: $cl-f-subheading;
  margin-top: -1px;
  padding: 1.5rem;

  &--highlight {
    background-color: $cl-bg-base;
  }

  &--with-button {
    padding: .6rem 1.5rem;
  }
}
