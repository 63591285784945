.leaflet-popup-content-wrapper {
  border-radius: 0;
  padding: 0;
}

.leaflet-popup-content {
  margin: 0;
}

.leaflet-popup-tip {
  background-color: $cl-gnaas-primary;
}

.leaflet-container a.leaflet-popup-close-button {
  color: $cl-bg-base;
}
