%abstract-form-element {
  border: {
    top: $border-formElement;
    bottom: $border-formElement;
  }
  margin-top: -1px;
  padding: .5em 1.5em;
  overflow: hidden;

  [layout="row"] > & {
    border-right: $border-formElement;

    &:last-child,
    &:last-of-type {
      border-right: none;
    }
  }

  md-input-container {
    margin-bottom: 0;
    padding: 0;
    width: 100%;

    label {
      padding-left: 0 !important;
    }

    &:not(.md-input-invalid).md-input-focused label,
    &:not(.md-input-invalid).md-input-has-value label,
    &:not(.md-input-invalid) label {
      color: $cl-f-subheading;
    }
  }

  .md-errors-spacer {
    min-height: 0;
  }
}
