// TRUMPS
// Styles with !important rule.

.t-no-padding { padding: 0 !important; }
.t-no-padding-x { padding: { left: 0 !important; right: 0 !important; } }
.t-no-padding-y { padding: { top: 0 !important; bottom: 0 !important; } }
.t-no-padding-top { padding-top: 0 !important; }
.t-no-padding-bottom { padding-bottom: 0 !important; }
.t-no-padding-left { padding-left: 0 !important; }
.t-no-padding-right { padding-right: 0 !important; }

.t-no-margin { margin: 0 !important; }
.t-no-margin-x { margin: { top: 0 !important; bottom: 0 !important; } }
.t-no-margin-y { margin: { top: 0 !important; bottom: 0 !important; } }
.t-no-margin-top { margin-top: 0 !important; }
.t-no-margin-bottom { margin-bottom: 0 !important; }
.t-no-margin-left { margin-left: 0 !important; }
.t-no-margin-right { margin-right: 0 !important; }

.t-color-base { color: $cl-f-base !important; }

.t-hide-leaflet-popup-tip .leaflet-popup-tip-container {
  display: none;
}

div.leaflet-container {
  z-index: 0;
}
