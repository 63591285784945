.form-select {
  @extend %abstract-form-element;

  .md-select-value {
    border: none;
    padding-left: 0;
    padding-bottom: 0;

    span:not(.md-select-icon) {
      overflow: visible;
    }

    span.md-select-icon {
      margin-right: 0;
    }
  }

  md-select:not([disabled]):focus .md-select-placeholder span:first-child,
  md-select .md-select-value.md-select-placeholder span:first-child,
  md-select:not([disabled]).ng-invalid.ng-dirty .md-select-value span:first-child {
    color: $cl-f-subheading;
  }

  md-select .md-select-value {
    background-image: none;
    border: none !important;
  }

  &.s-is-disabled .md-select-value {
    opacity: .7;
  }

  &--embedded {
    border: none !important;
    padding: 0;

    md-input-container {
      margin-top: 0;
    }
  }

  &__search-header {
    align-items: center;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
    cursor: pointer;
    display: flex;
    padding-left: 1em;
    position: relative;
    height: 48px;
    width: auto;
  }

  &__search-input {
    border: none;
    outline: none;
    padding: 0;
    height: 100%;
    width: 100%;
  }
}
