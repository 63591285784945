.c-request-log {
  &__controls {
    padding: 0 .5em 0 1.5em;
  }

  &__tab-content {
    padding: 1.5em;

    &__item {
      margin-bottom: 2em;

      &:last-child,
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
