$component: 'c-survey-item-details';

// TODO Refactor this
.#{$component} {
  &__header {
    background-color: $cl-bg-base;
    border-left: 6px solid $cl-bg-base;
    padding: 1em;

    &.s-is-amber {
      border-left-color: $cl-gnaas-accent;
    }

    &.s-is-red {
      border-left-color: $cl-gnaas-warning;
    }

    &.s-is-green {
      border-left-color: $cl-gnaas-primary;
    }
  }

  &.is-editing .#{$component}__header {
    padding: 0 1em;
  }
}
