.c-vehicle-callsign-status {
  &__status {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    line-height: 1.5rem;
    padding-left: 1.5rem;
    margin-bottom: 1rem;
    margin-left: -1.5rem;
    height: 24px;
    width: 128px;
    background: $cl-f-subheading;
    color: #FFF;
  }
}
